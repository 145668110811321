<template>
  <a-spin :spinning="spinning">
    <div :class="{ disabled: disabled }">
      <a-form-model layout="inline" :model="adjustForm" :rules="rules" ref="adjustForm" style="margin-top:3px">
        <a-card class="card" title="业务信息" :bordered="false" style="margin-top:3px;line-height: 1;">
          <div slot="extra">
            <a-affix
              :offsetTop="50"
              :style="{ position: 'absolute', top: '15px', left: 0, width: '98%', 'text-align': 'right' }"
            >
              <a-button><router-link :to="{ name: 'SaleAdjust' }">取消</router-link></a-button>
              <a-divider type="vertical" />
              <a-button :disabled="disabled ? true : false" type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
            </a-affix>
          </div>
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item label="业务单号">
                <a-input v-model="adjustForm.serial_num" @change="handleDetailByNum(adjustForm.serial_num)"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="业务类型">
                <a-select
                  showSearch
                  allowClear
                  v-model="adjustForm.bill_type"
                >
                  <a-select-option v-for="d in billTypeOps" :key="d.value">{{ d.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6" v-if="needSelectBalances">
              <a-form-model-item label="结算单号">
                <a-select
                  showSearch
                  allowClear
                  v-model="adjustForm.balance_ids"
                  mode="multiple"
                  style="width: 300px"
                  @change="handleBalanceIdChange"
                >
                  <a-select-option v-for="d in balanceInfoOps" :key="d.id">{{ d.serial_num }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6" v-if="adjustForm.bill_type===0">
              <a-form-model-item label="付款单号">
                <a-select
                  showSearch
                  allowClear
                  v-model="adjustForm.payment_ids"
                  mode="multiple"
                  style="width: 200px"
                >
                  <a-select-option v-for="d in paymentIdOps" :key="d.value">{{ d.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="调整项目（修改费用通过删除费用、添加费用操作）" :selfUpdate="false" prop="adjust_types">
                <a-checkbox-group :options="adjustTypeOps" v-model="adjustForm.adjust_types" @change="checkSelectBalance">
                  <span slot="label" slot-scope="option">{{ option.name }}</span>
                </a-checkbox-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="备注">
                <a-input style="width: 600px" v-model.trim="adjustForm.remark"/>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-card>
        <a-card v-if="adjustForm.bill_type!==0&&adjustForm.adjust_types.indexOf('重新开票')>=0 || adjustForm.adjust_types.indexOf('作废发票')>=0" class="card pc"
          :title="adjustForm.adjust_types.indexOf('作废发票')>=0?'作废发票':'重新开票'" :bordered="false"
          style="margin-top:-3px;line-height: 0.5;">
          <a-form-model-item label="原发票">
            <a-input style="width: 600px" placeholder="对账发票用;分割" v-model="adjustForm.original_invoices"/>
          </a-form-model-item>
        </a-card>
        <a-card v-if="adjustForm.bill_type!==0&&adjustForm.adjust_types.indexOf('新增费用')>=0" class="card" title="新增商品" :bordered="false">
          <a-tabs :activeKey="activeKey" @change="key => { activeKey = key }">
            <a-tab-pane tab="货物信息" key="1" v-if="adjustForm.bill_type===1">
              <edit-table
                :columns="goodsColumns"
                :sourceData="goodsData"
                :scroll="true"
                :operate="{ add: true, del: true, alldel: false }"
                @getTableDate="getGoodsData"
              ></edit-table>
            </a-tab-pane>
            <a-tab-pane tab="货物信息" key="1" v-if="adjustForm.bill_type===2">
              <edit-table
                :columns="agentGoodsColumns"
                :sourceData="agentGoodsData"
                :scroll="true"
                :operate="{ add: true, del: true, alldel: false }"
                @getTableDate="getAgentGoodsData"
              ></edit-table>
            </a-tab-pane>
          </a-tabs>
        </a-card>
        <a-card v-if="adjustForm.bill_type!==0&&adjustForm.adjust_types.indexOf('删除费用')>=0" class="card pc" title="删除商品" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
          <a-table
            v-if="adjustForm.bill_type===1"
            ref="table"
            size="small"
            row-key="id"
            :filterColumn="true"
            :columns="originalGoodsColumns"
            :data-source="originalGoodData"
            :row-selection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
            :scroll="{ x: 'max-content' }">
          </a-table>
          <a-table
            v-if="adjustForm.bill_type===2"
            ref="table"
            size="small"
            row-key="id"
            :filterColumn="true"
            :columns="originalAgentGoodsColumns"
            :data-source="originalAgentGoodData"
            :row-selection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
            :scroll="{ x: 'max-content' }">
          </a-table>
        </a-card>
        <a-card v-if="adjustForm.bill_type!==0&&adjustForm.adjust_types.indexOf('修改代理费')>=0" class="card pc" title="修改代理费" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="原代理费">
                <a-input-number style="width: 600px" v-model="adjustForm.original_agency_fee" disabled/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="新代理费">
                <a-input-number style="width: 600px" v-model="adjustForm.new_agency_fee"/>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-card>
        <a-card v-if="adjustForm.bill_type!==0&&adjustForm.adjust_types.indexOf('修改客户')>=0" class="card pc" title="修改客户" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="原客户名称">
                <a-input style="width: 300px" v-model="adjustForm.original_customer_name" disabled/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="新客户名称">
                <a-select
                  showSearch
                  label-in-value
                  :filter-option="false"
                  allowClear
                  placeholder="请选择委托单位"
                  style="width: 300px"
                  :value="customerInfo"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in agencyOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-card>
        <a-card v-if="adjustForm.bill_type===0&&adjustForm.adjust_types.indexOf('修改供应商(仅入库)')>=0" class="card pc" title="修改供应商(仅入库)" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="原供应商名称">
                <a-input style="width: 300px" v-model="adjustForm.original_customer_name" disabled/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="新供应商名称">
                <a-select
                  showSearch
                  allowClear
                  v-model="adjustForm.new_customer_id"
                  style="width: 300px"
                  @change="changeSupplierInfo"
                >
                  <a-select-option v-for="op in supplierOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-card>
      </a-form-model>
    </div>
  </a-spin>
</template>
<script>
import { getCustomer, getSupplier } from '@/api/common'
import { getFirstLetter } from '@/utils/util'
import {
  initSaleAdjust,
  getGoodStockInfo,
  getSellDetailBySellNo,
  getAgentDetailByAgentNo,
  getPurchaseDetailByPruchaseNo,
  saveSaleAdjustOrder,
  getGoodInfoList
} from '@/api/wms/sale'
import { EditTable } from '@/components'
import moment from 'moment'
import preventReClick from '@/utils/preventReClick' // 防多次点击，重复提交
import debounce from 'lodash/debounce'
export default {
  components: {
    EditTable,
    preventReClick
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    return {
      adjustForm: {
        order_id: null,
        balance_ids: [],
        payment_ids: [],
        serial_num: null,
        adjust_types: [],
        remark: null,
        original_customer_id: null,
        original_customer_name: null,
        new_customer_id: null,
        new_customer_name: null,
        add_sale_adjust_goods: [],
        delete_good_ids: [],
        original_invoices: null,
        original_agency_fee: null,
        new_agency_fee: null
      },
      businessExpand: true,
      allocationExpand: true,
      billInfoExpand: true,
      confirmLoading: false,
      spinning: false,
      isEdit: false,
      customerInfo: undefined,
      fetching: false,
      colSpan: {
        xxl: 6,
        lg: 7,
        md: 8
      },
      visible: false,
      disabled: false,
      customOps: [],
      supplierOps: [],
      adjustTypeOps: [],
      balanceInfoOps: [],
      paymentIdOps: [],
      needSelectBalances: false,
      billTypeOps: [],
      // 应收金额统计展示
      totalChargeFee: 0,
      // 应付金额统计展示
      totalPayFee: 0,
      // 表单校验
      rules: {
        // 报价编号
        serial_num: [{ required: true, message: '请输业务单号', trigger: 'blur' }],
        // 业务类型
        business_type: [{ required: true, message: '请选择业务类型', trigger: 'blur' }],
        // 服务类型
        service_types: [{ required: true, message: '请选择服务类型', trigger: 'blur' }]
      },
      agencyOps: [],
      activeKey: '1',
      stockGoodList: [],
      stockGoodOps: [],
      goodNoOps: [],
      goodsData: [],
      goodsColumns: [
        {
          title: `货物编码`,
          dataIndex: `good_no`,
          width: 150,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                disabled={record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)}
                filterOption={this.filterOption}
                onChange={val => {
                  let value = null;
                  for (const item of this.stockGoodList) {
                    if (item.id === val) {
                      value = item;
                    }
                  }
                  this.goodsData[index]['stock_id'] = value.id;
                  this.goodsData[index]['purchase_num'] = value.purchase_num;
                  this.goodsData[index]['good_id'] = value.good_id;
                  this.goodsData[index]['good_no'] = value.good_no;
                  this.goodsData[index]['good_name'] = value.good_name;
                  this.goodsData[index]['good_unit'] = value.good_unit;
                  this.goodsData[index]['good_spec'] = value.good_spec;
                  this.goodsData[index]['price_in'] = value.unit_price;
                  const data = [];
                  // 动态为 出货单价改变数据结构为 key: value 的形式
                  value.price_out.forEach(v => {
                    data.push({ name: v, value: v });
                  });
                  this.goodsColumns[6].extra.options = data;
                  this.goodsColumns = [...this.goodsColumns];
                  this.goodsData[index]['surplus_num'] = value.surplus_num;
                  this.goodsData[index]['supplier_id'] = value.supplier_id;
                  this.goodsData[index]['supplier_name'] = value.supplier_name;
                }}
              >
                {this.goodNoOps.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: '货物名称',
          dataIndex: 'good_name',
          width: 300,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                disabled={record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)}
                filterOption={this.filterOption}
                onChange={val => {
                  let value = null
                  for (const item of this.stockGoodList) {
                    if (item.id === val) {
                      value = item
                    }
                  }
                  // this.goodsData[index]['stock_id'] = value.id;
                  // this.goodsData[index]['purchase_num'] = value.purchase_num;
                  this.goodsData[index]['good_id'] = value.good_id;
                  this.goodsData[index]['good_no'] = value.good_no;
                  this.goodsData[index]['good_name'] = value.good_name;
                  this.goodsData[index]['good_unit'] = value.good_unit;
                  this.goodsData[index]['good_spec'] = value.good_spec;
                  this.goodsData[index]['price_in'] = value.unit_price;
                  const data = [];
                  // 动态为 出货单价改变数据结构为 key: value 的形式
                  value.price_out.forEach(v => {
                    data.push({ name: v, value: v });
                  });
                  this.goodsColumns[6].extra.options = data;
                  this.goodsColumns = [...this.goodsColumns];
                  this.goodsData[index]['surplus_num'] = value.surplus_num;
                  this.goodsData[index]['supplier_id'] = value.supplier_id;
                  this.goodsData[index]['supplier_name'] = value.supplier_name;
                }}
              >
                {this.stockGoodOps.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: '货物单位',
          dataIndex: 'good_unit',
          width: 60,
          extra: {
            type: 'text'
          }
        },
        {
          title: '货物规格',
          dataIndex: 'good_spec',
          width: 80,
          extra: {
            type: 'text'
          }
        },
        {
          title: '库存数量',
          dataIndex: 'surplus_num',
          width: 80,
          extra: {
            type: 'text'
          }
        },
        {
          title: '进货单价',
          dataIndex: 'price_in',
          width: 80,
          extra: {
            type: 'text'
          }
        },
        {
          title: '出货单价',
          dataIndex: 'price_out',
          width: 100,
          extra: {
            type: `select`,
            options: [],
            extraFunc: this.calcTotalPrice
          }
        },
        {
          title: '出货数量',
          dataIndex: 'num',
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                disabled={record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)}
                min={0}
                onChange={val => {
                  this.goodsData[index]['num'] = val
                  if (val && this.goodsData[index]['price_out']) {
                    this.goodsData[index]['charge'] = (val * this.goodsData[index]['price_out']).toFixed(2)
                  } else {
                    this.goodsData[index]['charge'] = 0
                  }
                }}
              />
            )
          }
        },
        {
          title: '出货总价',
          dataIndex: 'charge',
          width: 120,
          extra: {
            type: 'text'
          }
        },
        {
          title: '打包销售名称',
          dataIndex: 'package_sell_name',
          width: 200,
          extra: {
            type: 'select',
            options: [
              { 'name': '无', 'value': '' },
              { 'name': '远洋蓝精酿全家福啤酒', 'value': '远洋蓝精酿全家福啤酒' }
            ]
          }
        },
        {
          title: `供应商`,
          dataIndex: `supplier_name`,
          width: 150,
          extra: {
            type: `text`
          }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 120
        }
      ],
      agentGoodsData: [],
      agentGoodsColumns: [
        {
          title: `货物编码`,
          dataIndex: `good_no`,
          width: 150,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                disabled={record.change_status === 1 || record.pay_status === 1}
                filterOption={this.filterOption}
                onChange={val => {
                  let value = null;
                  for (const item of this.goodInfoList) {
                    if (item.id === val) {
                      value = item;
                    }
                  }
                  this.agentGoodsData[index]['good_id'] = value.id;
                  this.agentGoodsData[index]['good_no'] = value.good_no;
                  this.agentGoodsData[index]['good_name'] = value.good_name;
                  this.agentGoodsData[index]['good_unit'] = value.good_unit;
                  this.agentGoodsData[index]['good_spec'] = value.good_spec;
                  // this.goodsData[index]['price_in'] = value.price_in;
                  const priceInData = [];
                  const priceOutData = [];
                  value.price_in.forEach(v => priceInData.push({ name: v, value: v }));
                  value.price_out.forEach(v => priceOutData.push({ name: v, value: v }));
                  this.agentGoodsColumns[4].extra.options = priceInData;
                  this.agentGoodsColumns[5].extra.options = priceOutData;
                  this.agentGoodsColumns = [...this.goodsColumns];
                }}
              >
                {this.goodNoOps.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: '货物名称',
          dataIndex: 'good_name',
          width: 500,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                disabled={record.change_status === 1 || record.pay_status === 1}
                filterOption={this.filterOption}
                onChange={val => {
                  let value = null
                  for (const item of this.goodInfoList) {
                    if (item.id === val) {
                      value = item
                    }
                  }
                  this.agentGoodsData[index]['good_id'] = value.id;
                  this.agentGoodsData[index]['good_no'] = value.good_no;
                  this.agentGoodsData[index]['good_name'] = value.good_name;
                  this.agentGoodsData[index]['good_unit'] = value.good_unit;
                  this.agentGoodsData[index]['good_spec'] = value.good_spec;
                  // this.goodsData[index]['price_in'] = value.price_in;
                  const priceInData = [];
                  const priceOutData = [];
                  value.price_in.forEach(v => priceInData.push({ name: v, value: v }));
                  value.price_out.forEach(v => priceOutData.push({ name: v, value: v }));
                  this.agentGoodsColumns[4].extra.options = priceInData;
                  this.agentGoodsColumns[5].extra.options = priceOutData;
                  this.agentGoodsColumns = [...this.agentGoodsColumns];
                }}
              >
                {this.goodInfoOps.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: '货物单位',
          dataIndex: 'good_unit',
          width: 80,
          extra: {
            type: 'text',
            disabled: record => {
              return record.change_status === 1 || record.pay_status === 1
            }
          }
        },
        {
          title: '货物规格',
          dataIndex: 'good_spec',
          width: 80,
          extra: {
            type: 'text',
            disabled: record => {
              return record.change_status === 1 || record.pay_status === 1
            }
          }
        },
        {
          title: '进货单价',
          dataIndex: 'price_in',
          width: 120,
          extra: {
            type: 'select',
            options: [],
            disabled: record => {
              return record.pay_status === 1
            }
          }
        },
        {
          title: '出货单价',
          dataIndex: 'price_out',
          width: 120,
          extra: {
            type: `select`,
            options: [],
            extraFunc: this.calcTotalPrice,
            disabled: record => {
              return record.change_status === 1
            }
          }
        },
        {
          title: '出货数量',
          dataIndex: 'num',
          width: 110,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width: 100%"
                value={text}
                disabled={record.change_status === 1 || record.pay_status === 1}
                min={0}
                onChange={val => {
                  this.agentGoodsData[index]['num'] = val
                  if (val && this.agentGoodsData[index]['price_out']) {
                    this.agentGoodsData[index]['charge_total_price'] = (val * this.agentGoodsData[index]['price_out']).toFixed(2)
                  } else {
                    this.agentGoodsData[index]['charge_total_price'] = 0
                  }
                }}
              />
            )
          }
        },
        {
          title: '出货总价',
          dataIndex: 'charge_total_price',
          width: 120,
          extra: {
            type: `text`,
            disabled: record => {
              return record.change_status === 1 || record.pay_status === 1
            }
          }
        },
        {
          title: '供应商',
          dataIndex: 'supplier_id',
          width: 220,
          extra: {
            type: 'select',
            func: getSupplier,
            params: [ { department: 8 } ],
            options: []
            // disabled: record => {
            //   return record.change_status === 1 || record.pay_status === 1
            // }
          }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 120,
          extra: {
            disabled: record => {
              return record.change_status === 1 || record.pay_status === 1
            }
          }
        }
      ],
      selectedRowKeys: [],
      selectedRows: [],
      originalGoodData: [],
      originalGoodsColumns: [
        {
          title: '进货单',
          dataIndex: 'purchase_num',
          width: 200
        },
        {
          title: `货物编码`,
          dataIndex: `good_no`,
          width: 150
        },
        {
          title: '货物名称',
          dataIndex: 'good_name',
          width: 300
        },
        {
          title: '货物单位',
          dataIndex: 'good_unit',
          width: 60
        },
        {
          title: '货物规格',
          dataIndex: 'good_spec',
          width: 80
        },
        {
          title: '库存数量',
          dataIndex: 'surplus_num',
          width: 80
        },
        {
          title: '进货单价',
          dataIndex: 'price_in',
          width: 80
        },
        {
          title: '出货单价',
          dataIndex: 'price_out',
          width: 100
        },
        {
          title: '出货数量',
          dataIndex: 'num',
          width: 100
        },
        {
          title: '出货总价',
          dataIndex: 'charge',
          width: 120
        },
        {
          title: '打包销售名称',
          dataIndex: 'package_sell_name',
          width: 200
        },
        {
          title: '交易号',
          dataIndex: 'trx_num',
          width: 120
        },
        {
          title: `交易时间`,
          dataIndex: `pay_time`,
          width: 150
        },
        {
          title: `供应商`,
          dataIndex: `supplier_name`,
          width: 150
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 120
        }
      ],
      originalAgentGoodData: [],
      originalAgentGoodsColumns: [
        {
          title: `货物编码`,
          dataIndex: `good_no`,
          width: 150
        },
        {
          title: '货物名称',
          dataIndex: 'good_name',
          width: 500
        },
        {
          title: '货物单位',
          dataIndex: 'good_unit',
          width: 80
        },
        {
          title: '货物规格',
          dataIndex: 'good_spec',
          width: 80
        },
        {
          title: '进货单价',
          dataIndex: 'price_in',
          width: 120
        },
        {
          title: '出货单价',
          dataIndex: 'price_out',
          width: 120
        },
        {
          title: '出货数量',
          dataIndex: 'num',
          width: 110
        },
        {
          title: '出货总价',
          dataIndex: 'charge_total_price',
          width: 120
        },
        {
          title: '供应商',
          dataIndex: 'supplier_name',
          width: 220
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 120
        }
      ]
    }
  },
  watch: {
    $route: function(newRoute) {
      // this.disabled = false
      // if (!this.queryParam && newRoute.name === 'SeaOrderCUR') {
      //   // 编辑页路由监控
      //   this.handleFormReset()
      //   this.$route.meta.title = '新增订单'
      //   if (newRoute.params.id === 'create' || newRoute.query.isCopy) {
      //     // 新增或复制
      //     if (newRoute.query.isCopy) {
      //       this.getDetailData(newRoute.params.id, true)
      //     } else {
      //       this.isEdit = true
      //     }
      //   } else {
      //     this.disabled = newRoute.query.disabled
      //     this.getDetailData(newRoute.params.id)
      //   }
      // }
    }
  },
  created() {
    getSupplier({ department: 8 }).then(res => {
      this.supplierOps = res
    });
    this.resetAdjustForm()
  },
  mounted() {
    this.init()
    this.resetAdjustForm()
    getGoodInfoList().then(res => {
      this.goodInfoList = res
      this.goodInfoOps = res.map(e => {
        return {
          name: `${e.good_name}/${e.good_unit}/${e.good_spec}`,
          value: e.id
        }
      });
      this.goodNoOps = res.filter(e => e.good_no && e.good_no !== '').map(e => {
        return {
          name: e.good_no,
          value: e.id
        }
      })
    });
    this.getGoodStockInfo()
  },
  methods: {
    init() {
      initSaleAdjust().then(res => {
        this.adjustTypeOps = res.ADJUST_TYPE_LIST
        this.adjustStatusOps = res.ADJUST_STATUS_LIST
        this.billTypeOps = res.BILL_TYPE_LIST
      })
    },
    getGoodsData(data) {
      this.goodsData = data;
    },
    getAgentGoodsData(data) {
      this.agentGoodsData = data;
    },
    getGoodStockInfo() {
      getGoodStockInfo().then(res => {
        this.stockGoodList = res
        this.stockGoodOps = res.map(e => {
          return {
            name: `${e.good_name}/${e.good_spec}/${e.purchase_num}`,
            value: e.id
          }
        });
        this.goodNoOps = res.filter(e => e.good_no && e.good_no !== '').map(e => {
          return {
            name: e.good_no,
            value: e.id
          }
        })
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggle(type) {
      if (type === 'business') {
        this.businessExpand = !this.businessExpand
      } else if (type === 'allocation') {
        this.allocationExpand = !this.allocationExpand
      } else if (type === 'billInfo') {
        this.billInfoExpand = !this.billInfoExpand
      }
    },
    radioChange() {
      const type = this.form['business_type'];
      if (type === 3 || type === 4) {
        this.form['agent_aboard'] = null;
      }
    },
    moment,
    handleFormReset() {
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.agencyOps = []
      this.fetching = true
      getCustomer({
        department: 8,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.agencyOps = res
        this.fetching = false
      })
    },
    getDepartmentSupplier(value) {
      this.supplierOps = []
      getSupplier({
        department: 8
      }).then(res => {
        this.supplierOps = res
      })
    },
    changeSupplierInfo(value) {
      this.adjustForm.new_customer_id = value
      this.supplierOps.forEach(e => {
        if (e.id === value) {
          this.adjustForm.new_customer_name = e.name
        }
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.adjustForm.new_customer_id = value.key
      this.adjustForm.new_customer_name = value.label
      this.agencyOps = []
      this.fetching = false
    },
    handleDetailByNum(serialNum) {
      this.originalGoodData = []
      this.originalAgentGoodData = []
      this.balanceInfoOps = []
      this.paymentIdOps = []
      if (!serialNum) {
        this.resetAdjustForm()
        return
      }
      if (serialNum.indexOf('OGJXR') >= 0) {
        this.adjustForm.bill_type = 0
      }
      if (serialNum.indexOf('OGJXC') >= 0) {
        this.adjustForm.bill_type = 1
      }
      if (serialNum.indexOf('OGJXD') >= 0) {
        this.adjustForm.bill_type = 2
      }
      this.spinning = true
      if (this.adjustForm.bill_type === 1) {
        getSellDetailBySellNo({ sellNo: serialNum }).then(v => {
          // this.selectItmsOrder = v.order
          this.adjustForm.order_id = v.sell.id
          this.adjustForm.serial_num = v.sell.sell_num
          this.adjustForm.original_customer_name = v.sell.buyer_name
          this.adjustForm.original_customer_id = v.sell.buyer
          // this.adjustForm.original_invoices = Array.from(v.invoice_nos).join(';')
          this.adjustForm.original_agency_fee = v.sell.agency_fee ? v.sell.agency_fee : 0
          v.good_out_list.forEach(item => {
            this.originalGoodData.push(item);
          })
          var orginalInoivces = []
          console.info(v.balances)
          for (let index = 0; index < v.balances.length; index++) {
            const item = v.balances[index];
            this.balanceInfoOps.push(item);
            if (item.invoice_nos) {
              orginalInoivces.push(item.invoice_nos)
            }
          }
          this.adjustForm.original_invoices = Array.from(orginalInoivces).join(';')
          this.spinning = false
        })
        .catch(_ => {
          this.spinning = false
        })
      } else if (this.adjustForm.bill_type === 2) {
        getAgentDetailByAgentNo({ agentNo: serialNum }).then(v => {
          // this.selectItmsOrder = v.order
          this.adjustForm.order_id = v.agent.id
          this.adjustForm.serial_num = v.agent.agent_num
          this.adjustForm.original_customer_name = v.agent.buyer_name
          this.adjustForm.original_customer_id = v.agent.buyer
          // this.adjustForm.original_invoices = Array.from(v.invoice_nos).join(';')
          this.adjustForm.original_agency_fee = v.agent.agency_fee ? v.agent.agency_fee : 0
          v.good_agent_list.forEach(item => {
            this.originalAgentGoodData.push(item);
          })
          var orginalInoivces = []
          console.info(v.balances)
          for (let index = 0; index < v.balances.length; index++) {
            const item = v.balances[index];
            this.balanceInfoOps.push(item);
            if (item.invoice_nos) {
              orginalInoivces.push(item.invoice_nos)
            }
          }
          this.adjustForm.original_invoices = Array.from(orginalInoivces).join(';')
          this.spinning = false
        })
        .catch(_ => {
          this.spinning = false
        })
      } else if (this.adjustForm.bill_type === 0) {
        this.getDepartmentSupplier()
        getPurchaseDetailByPruchaseNo({ purchaseNo: serialNum }).then(v => {
          // this.selectItmsOrder = v.order
          this.adjustForm.order_id = v.purchase.id
          this.adjustForm.serial_num = v.purchase.purchase_num
          this.adjustForm.original_customer_name = v.purchase.supplier_name
          this.adjustForm.original_customer_id = v.purchase.supplier_id
          for (let index = 0; index < v.payments.length; index++) {
            const item = v.payments[index];
            this.paymentIdOps.push({ 'value': item.id, 'name': item.serial_num });
          }
          this.spinning = false
        })
        .catch(_ => {
          this.spinning = false
        })
      }
    },
    resetAdjustForm() {
      this.adjustForm = {
        order_id: null,
        balance_ids: [],
        payment_ids: [],
        serial_num: null,
        adjust_types: [],
        remark: null,
        original_customer_id: null,
        original_customer_name: null,
        new_customer_id: null,
        new_customer_name: null,
        add_charge_fees: [],
        add_pay_fees: [],
        delete_fee_ids: [],
        original_invoices: null,
        original_agency_fee: null,
        new_agency_fee: null
      }
      this.originalGoodData = []
    },
    handleAutoSearch(inputValue, option) {
      if (inputValue) {
        return option.key.toLowerCase().includes(inputValue.toLowerCase())
      } else {
        return true
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    calcTotalInfo(value, newData, target) {
      let totalNum = 0
      let totalVolumn = 0
      newData.forEach(row => {
        totalNum += row.num || 0
        totalVolumn += parseFloat(row.volume) || 0
      })
      this.form.setFieldsValue({ total_num: totalNum, volume: totalVolumn })
      return newData
    },
    handleSubmit() {
      if (this.needSelectBalances && this.adjustForm.balance_ids.length === 0) {
        this.$notification['info']({
          message: '提示',
          description: '请选择结算单'
        });
        return
      }
      this.$refs.adjustForm.validate(valid => {
        if (valid) {
          if (this.adjustForm.bill_type === 1) {
            this.adjustForm.add_sale_adjust_goods = this.goodsData
          } else if (this.adjustForm.bill_type === 2) {
            this.adjustForm.add_sale_adjust_goods = this.agentGoodsData
          }
          this.adjustForm.delete_good_ids = this.selectedRowKeys
          console.log(this.adjustForm)
          saveSaleAdjustOrder(this.adjustForm).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: this.isEdit ? '编辑成功' : '创建成功.'
            });
            this.disabled = true
          });
        } else {
          return false;
        }
      });
    },
    checkSelectBalance() {
      if (this.adjustForm.adjust_types) {
        for (let i = 0; i < this.adjustForm.adjust_types.length; i++) {
          if (this.adjustForm.adjust_types[i] !== '修改客户'&&this.adjustForm.adjust_types[i] !== '重新开票') {
            this.needSelectBalances = false;
            return false;
          }
        }
      }
      this.needSelectBalances = true;
    },
    handleBalanceIdChange() {
      if (this.adjustForm.balance_ids.length > 0) {
        var orginalInoivces = []
        this.adjustForm.balance_ids.forEach(item => {
          this.balanceInfoOps.forEach(element => {
            console.info(element.id, item, element.id === item);
            if (element.id === item) {
              orginalInoivces.push(element.invoice_nos)
            }
            console.info(orginalInoivces)
          });
        })
        this.adjustForm.original_invoices = Array.from(orginalInoivces).join(';')
      } else {
        this.adjustForm.original_invoices = ''
      }
    }
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 10px;
}
.table-title {
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 800;
  /* color: #1890ff */
}
.field-item {
  position: relative;
  width: 200px;
  margin-right: 10px;
  margin-top: 10px;
  color: red;
}
.field-item:last-child {
  width: 280px;
}
.field-item_del {
  position: absolute;
  top: -5px;
  left: 190px;
  color: #ccc;
  z-index: 999;
  cursor: pointer;
}
.field-item_del:hover,
.send-item_del:hover {
  color: #1890ff;
}
</style>
<style>
.pc .ant-drawer {
  display: none;
}
.pc .ant-drawer-open {
  display: block;
}
.field-item.store-type-item,
.field-item.store-type-item:last-child {
  width: 100%;
}
.field-item.store-type-item .field-item_del {
  left: calc(100% - 110px);
}
.field-item.send-item,
.field-item.send-item:last-child {
  width: 100%;
}
.field-item.send-item .field-item_del {
  left: calc(100% - 110px);
}
.ant-card-body {
    padding: 12px;
    zoom: 1;
}
.ant-table-placeholder {
    padding: 6px;
}
.ant-card-head {
    min-height: 36px;
    margin-bottom: -1px;
    padding: 0 24px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    background: transparent;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 2px 2px 0 0;
    zoom: 1;
}
.ant-card-head-title {
    display: inline-block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 16px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 1000
}
</style>
